<template>
  <PreloaderComponent />
  <div
    class="edrea_tm_all_wrap"
    data-magic-cursor="show"
    data-enter="fadeInUp"
    data-exit=""
  >
    <!-- WAVE DOTS -->
    <div class="waves" style="background-color: #FAFAFA;">
      {{ waves }}
    </div>
    <!-- WAVE DOTS -->


    <!-- HEADER -->
    <HeaderComponent />
    <!-- /HEADER -->

    <!-- CONTENT -->
    <div class="edrea_tm_content">
      <!-- HOME -->
      <HomeComponent />
      <!-- /HOME -->

      <!-- ABOUT -->
      <AboutComponent />
      <!-- /ABOUT -->

      <!-- CONTACT -->
      <ContactComponent />
      <!-- /CONTACT -->

       <!-- INFOS -->
       <InfoComponent />
      <!-- /INFOS -->

    </div>
    <!-- /CONTENT -->

  </div>
</template>

<script>
import PreloaderComponent from "@/components/layout/PreloaderComponent.vue";
import AboutComponent from "../components/AboutComponent.vue";
import InfoComponent from "../components/InfoComponent.vue";
import ContactComponent from "../components/ContactComponent.vue";
import HomeComponent from "../components/HomeComponent.vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import { dataImage, imgToSVG } from "../utilits";
import { waves } from "../waves";

export default {
  name: `HomeView`,
  mounted() {
    waves();
    imgToSVG();
    dataImage();
  },
  components: {
    HomeComponent,
    AboutComponent,
    InfoComponent,
    ContactComponent,
    HeaderComponent,
    PreloaderComponent,
  },
};
</script>

<!-- about chart -->
